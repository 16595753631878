import React from "react";

import { OxCallout } from "src/components/OxCallout";
import { OxDoctorsAccordion } from "src/components/OxDoctorsAccordion";
import { OxExperienceGrid } from "src/components/OxExperienceGrid";
import { OxFeatureBlock } from "src/components/OxFeatureBlock";
import { OxFullBleedFeature } from "src/components/OxFullBleedFeature";
import { OxBanner } from "src/components/OxBanner";
import { OxMediaCarousel } from "src/components/OxMediaCarousel";
import { OxQuoteSlider } from "src/components/OxQuoteSlider";
import { OxServiceSet } from "src/components/OxServiceSet";
import { OxSplitBlock } from "src/components/OxSplitBlock";
import { OxStory } from "src/components/OxStory";
import { OxRichStory } from "src/components/OxRichStory";
import { OxTextCallout } from "src/components/OxTextCallout";
import { OxContactUs } from "src/components/OxContactUs";
import { OxCarousel } from "src/components/OxCarousel";

const map: { [key: string]: React.FC<any> } = {
  OxCallout,
  OxDoctorsAccordion,
  OxExperienceGrid,
  OxFeatureBlock,
  OxFullBleedFeature,
  OxBanner,
  OxMediaCarousel,
  OxQuoteSlider,
  OxCarousel,
  OxServiceSet,
  OxSplitBlock,
  OxStory,
  OxRichStory,
  OxTextCallout,
  OxContactUs,
};

export const resolve = (name: string): React.FC<any> => {
  if (!(name in map)) throw new Error("Requested component not in map");
  return map[name];
};
