import styled from "styled-components";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";

export const Wrapper = styled.div`
  text-align: center;

  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Medium,
    [12, 12, 12],
    [16, 16, 16]
  )}
`;
