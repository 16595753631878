import styled, { css } from "styled-components";
import { OxContainer } from "src/components/OxContainer";
import { fluidSizing, toVw } from "src/utils";
import { OxImage } from "src/components/OxImage";
import { ESizes } from "src/config/enums";

export const List = styled(OxContainer)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;

    ${fluidSizing([
      { prop: "column-gap", values: [10, 10, 10] },
      { prop: "row-gap", values: [35, 35, 35] }
    ])}

    ${theme.breakpoints.down("xs")} {
      padding-left: 0;
      padding-right: 0;
    }

    ${theme.breakpoints.only("s")} {
      grid-template-columns: 1fr 1fr;
    }

    ${theme.breakpoints.up("m")} {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `
);

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Text = styled.div(
  ({ theme }) => css`
    ${fluidSizing([
      { prop: "padding-bottom", values: [15, 15, 15] },
      { prop: "padding-left", values: [15, 15, 15] },
      { prop: "padding-right", values: [15, 15, 15] }
    ])}
    ${theme.breakpoints.only("xs")} {
      padding-left: ${toVw(ESizes.GutterXS, "xs")};
      padding-right: ${toVw(ESizes.GutterXS, "xs")};
    }
  `
);

export const Image = styled(OxImage)`
  margin-top: auto;
`;
