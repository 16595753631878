import { fluidSizing, toVw } from "src/utils";
import styled, { css } from "styled-components";

import { EOxRichStoryVariant } from "./OxRichStory";
import { OxContainer } from "src/components/OxContainer";
import { OxImage } from "src/components/OxImage";
import { OxLinkWithLine } from "src/components/OxLinkWithLine";
import { EFonts, EFontWeight, ESizes } from "src/config/enums";
import { OxAutoScrollImages } from "src/components/OxAutoScrollImages";
import { TBasicTheme } from "src/types/theme.types";
import { createTextStyle } from "src/utils/createTextStyle";

export const Container = styled(OxContainer)<{
  variant: EOxRichStoryVariant;
}>(({ theme, variant }) => {
  const commonStyles = css`
    display: flex;
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }
  `;

  let variantStyles;
  switch (variant) {
    case EOxRichStoryVariant.ImageRight:
      variantStyles = css`
        ${theme.breakpoints.up("s")} {
          flex-direction: row-reverse;
        }
      `;
      break;
    case EOxRichStoryVariant.ImageLeft:
      variantStyles = css`
        ${theme.breakpoints.up("s")} {
          flex-direction: row;
        }
      `;
      break;
  }

  return css`
    ${commonStyles};
    ${variantStyles};
  `;
});

export const ContentWrapper = styled.div<{
  variant: EOxRichStoryVariant;
  $fullBleedContent?: boolean;
  isLast?: boolean;
}>(
  ({ theme, variant, $fullBleedContent, isLast }) => css`
    width: 100%;

    ${theme.breakpoints.up("s")} {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    ${fluidSizing([
      {
        prop: "padding-left",
        values:
          $fullBleedContent && variant === EOxRichStoryVariant.ImageRight
            ? [ESizes.GutterXS, null, null]
            : [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM],
      },
      {
        prop: "padding-right",
        values:
          $fullBleedContent && variant === EOxRichStoryVariant.ImageLeft
            ? [ESizes.GutterXS, null, null]
            : [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM],
      },
    ])}

    ${$fullBleedContent &&
    !isLast &&
    css`
      ${theme.breakpoints.only("xs")} {
        margin-bottom: ${toVw(20, "xs")};
      }
    `}
  `
);

const sharedAssetStyles = (
  theme: TBasicTheme
  // $fullBleedContent?: boolean
) => css`
  ${theme.breakpoints.only("xs")} {
    width: 100%;
  }
  ${theme.breakpoints.up("s")} {
    width: 50%;
    height: auto;
  }
  ${fluidSizing([
    // { prop: "height", values: [300, $fullBleedContent ? 400 : 320, 400] },
    { prop: "margin-bottom", values: [20, null, null] },
  ])}
`;

export const AutoScrollAsset = styled(OxAutoScrollImages)<{
  $fullBleedContent?: boolean;
}>(({ theme, $fullBleedContent }) =>
  sharedAssetStyles(theme, $fullBleedContent)
);

export const Asset = styled(OxImage)<{
  $fullBleedContent?: boolean;
}>(({ theme, $fullBleedContent }) =>
  sharedAssetStyles(theme, $fullBleedContent)
);

export const Link = styled(OxLinkWithLine)(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
  `
);

export const ImageWrapper = styled.div`
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;

export const PortableContainer = styled.div(
  ({ theme }) => css`
    ${fluidSizing([{ prop: "margin-bottom", values: [6, 12, 12] }])}
    h1 {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [24, 24, 24],
        [26, 26, 26]
      )}
      ${fluidSizing([{ prop: "margin-bottom", values: [6, 12, 12] }])}
    }

    h2 {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [20, 20, 20],
        [24, 24, 24]
      )}
      ${fluidSizing([{ prop: "margin-bottom", values: [6, 12, 12] }])}
    }

    h3 {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [16, 16, 16],
        [20, 20, 20]
      )}
      ${fluidSizing([{ prop: "margin-bottom", values: [6, 12, 12] }])}
    }

    h4 {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [12, 16, 18],
        [14, 18, 20]
      )}
    }

    h5 {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [12, 14, 14],
        [14, 16, 20]
      )}
    }

    h6 {
      text-transform: uppercase;
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.Medium,
        [12, 12, 12],
        [16, 16, 16]
      )};
    }

    blockquote {
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.Medium,
        [11, 11, 11],
        [14, 14, 14]
      )}
    }

    ul {
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.Medium,
        [11, 11, 11],
        [14, 14, 14]
      )}

      ${fluidSizing([{ prop: "margin-bottom", values: [14, 14, 14] }])}
      li {
        list-style: disc;
        list-style-position: inside;
      }
    }

    ol {
      ${createTextStyle(
        EFonts.Montserrat,
        EFontWeight.Medium,
        [11, 11, 11],
        [14, 14, 14]
      )}

      ${fluidSizing([{ prop: "margin-bottom", values: [14, 14, 14] }])}

      li {
        list-style: decimal;
        ${fluidSizing([{ prop: "padding-left", values: [3, 3, 3] }])}
      }
    }

    a {
      display: inline;
      border-bottom: 1px solid ${theme.colors.basic.swissCoffee};
    }
  `
);
