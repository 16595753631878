import * as Styled from "./OxOrderSummary.styled";

import { OxContainer } from "src/components/OxContainer";
import { OxFlex } from "src/components/OxFlex";
import React from "react";

type TProps = {
  title: string | JSX.Element;
  subtitle?: string;
  hideSummary?: boolean;
  summaryTitle: string;
  summaryData: { title: string; value: string; hide?: boolean }[];
  bottomText?: string | JSX.Element;
};

export const OxOrderSummary = (props: TProps): JSX.Element => {
  return (
    <Styled.Container>
      <OxContainer>
        <OxFlex flexDirectionColumn alignItemsCenter>
          <Styled.Heading>{props.title}</Styled.Heading>
          {props.subtitle && (
            <Styled.Subtitle>{props.subtitle}</Styled.Subtitle>
          )}
        </OxFlex>
      </OxContainer>
      {!props.hideSummary && (
        <Styled.Summary>
          <Styled.Paragraph>{props.summaryTitle}</Styled.Paragraph>
          <Styled.SummaryData>
            {props.summaryData.map((item, index) => (
              <React.Fragment key={index}>
                {!item.hide && (
                  <>
                    <Styled.Paragraph>{item.title}</Styled.Paragraph>
                    <Styled.Paragraph>{item.value}</Styled.Paragraph>
                  </>
                )}
              </React.Fragment>
            ))}
          </Styled.SummaryData>
        </Styled.Summary>
      )}
      {props.bottomText && (
        <Styled.Footer>
          <span>{props.bottomText}</span>
        </Styled.Footer>
      )}
    </Styled.Container>
  );
};
