import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { OxLink } from "src/components/OxLink";
import { OxContainer } from "src/components/OxContainer";
import { transparentize } from "polished";
import { TBasicTheme } from "src/types/theme.types";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFontWeight, EFonts } from "src/config/enums";
import { OxLinkWithLine } from "src/components/OxLinkWithLine";

export const Container = styled(OxContainer)<{ fullBleed?: boolean }>(
  ({ fullBleed }) => css`
    ${fullBleed &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
  `
);

export const Items = styled.div(
  ({ theme }) => `
  display: flex;

  ${theme.breakpoints.only("xs")} {
    flex-direction: column;
  }
`
);

const ItemStyles = (theme: TBasicTheme, hideOnMobile?: boolean) => css`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  ${fluidSizing([
    { prop: "margin-right", values: [0, 20, 20] },
    { prop: "margin-bottom", values: [10, null, null] },
  ])}
  ${theme.breakpoints.down("s")} {
    ${hideOnMobile &&
    css`
      display: none;
    `}
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const ItemLink = styled(OxLink)<{ hideOnMobile?: boolean }>(
  ({ theme, hideOnMobile }) => ItemStyles(theme, hideOnMobile)
);

export const ItemNonLink = styled.div<{ hideOnMobile?: boolean }>(
  ({ theme, hideOnMobile }) => ItemStyles(theme, hideOnMobile)
);

export const Content = styled.div<{
  contentOverImage?: boolean;
}>(
  ({ theme, contentOverImage }) => css`
    ${contentOverImage &&
    css`
      transition: background-color 0.3s ease-out;
      align-items: center;
      background: ${transparentize(0.3, theme.colors.basic.white)};
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      &:hover {
        background: ${transparentize(0.5, theme.colors.basic.white)};
      }
    `}
  `
);

export const ImageWrapper = styled.div<{
  contentOverImage?: boolean;
}>(
  ({ contentOverImage }) => css`
    ${!contentOverImage &&
    css`
      ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
    `}
  `
);

export const sub = styled.span(
  () => css`
    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Medium,
      [15, 18, 18],
      [24, 30, 36]
    )}
  `
);

export const Link = styled(OxLinkWithLine)(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
  `
);
