import styled, { css } from "styled-components";

import { fluidSizing } from "src/utils";

export const Container = styled.div<{
  show: boolean;
  position?: string;
  noHeight?: boolean;
}>(
  ({ theme, show, position, noHeight }) => css`
    position: ${position || "absolute"};
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.basic.ouronyxLogo};
    visibility: hidden;
    opacity: 0;
    transition: 500ms;
    z-index: 10;
    display: flex;
    flex-direction: column;
    ${!noHeight && fluidSizing([{ prop: "height", values: [620, 600, 600] }])}
    /* max-height: 100vh; */
    ${show &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  `
);
