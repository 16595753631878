import * as Styled from "./OxSplitBlock.styled";

import React from "react";
import { OxLineHeader } from "src/components/OxLineHeader";
import { OxAutoScrollImages } from "src/components/OxAutoScrollImages";
import { OxTextHeading } from "src/components/OxTextHeading";
import { OxTextParagraph } from "src/components/OxTextParagraph";
import { generateImgSizes } from "src/utils";
import { OxContainerGutter } from "src/components/OxContainer";

export type TBlock = {
  fluidImagesArray?: any[];
  title?: string;
  link?: string;
  linkTitle?: string;
  note?: string;
  description?: string;
  contentOverImage?: boolean;
  hideOnMobile?: boolean;
};

type TProps = {
  header?: string;
  fullBleed?: boolean;
  items: TBlock[];
};

export const OxSplitBlock = ({
  items = [],
  header,
  fullBleed,
}: TProps): JSX.Element => {
  // As close an approximation as we're going to be able to get with this really
  const imageSizes = generateImgSizes({
    values: OxContainerGutter,
    formatter: (val, breakpoint) =>
      `${(100 - val * 2) / (breakpoint === "xs" ? 1 : items.length)}vw`,
  });

  return (
    <Styled.Container fullBleed={fullBleed}>
      {header && <OxLineHeader>{header}</OxLineHeader>}
      {items.length > 0 && (
        <Styled.Items>
          {items.map((item, index) => {
            const component = item.link ? Styled.ItemLink : Styled.ItemNonLink;
            const children = [];

            item.fluidImagesArray &&
              children.push(
                <Styled.ImageWrapper
                  key={`${index}_image`}
                  contentOverImage={item.contentOverImage}
                >
                  {item.fluidImagesArray && (
                    <OxAutoScrollImages
                      alt={item.title}
                      images={item.fluidImagesArray}
                      sizes={imageSizes}
                    />
                  )}
                </Styled.ImageWrapper>
              );

            children.push(
              <Styled.Content
                key={`${index}_content`}
                contentOverImage={item.contentOverImage}
              >
                {item.title && (
                  <OxTextHeading>
                    {item.title}
                    {item.note && <Styled.sub> ({item.note})</Styled.sub>}
                  </OxTextHeading>
                )}
                {item.description && (
                  <OxTextParagraph allowLineBreaks>
                    {item.description}
                  </OxTextParagraph>
                )}
                {item.linkTitle && (
                  <Styled.Link asSpan={!!item.link} to={item.link}>
                    {item.linkTitle}
                  </Styled.Link>
                )}
              </Styled.Content>
            );

            return React.createElement(
              component,
              {
                key: index,
                to: item.link,
                hideOnMobile: item.hideOnMobile,
              },
              children
            );
          })}
        </Styled.Items>
      )}
    </Styled.Container>
  );
};
