import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { fluidSizing } from "src/utils";

export const LocationSwitcher = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  ${fluidSizing([{ prop: "margin-bottom", values: [8, 10, 10] }])}
`;

export const LocationSwitch = styled.button(
  ({ theme, disabled }) => css`
    color: ${disabled ? theme.colors.primary.main : theme.colors.basic.black};
    ${disabled &&
      css`
        cursor: default;
      `}
    position: relative;
    text-transform: uppercase;

    ${createTextStyle(
      EFonts.Montserrat,
      disabled ? EFontWeight.Bold : EFontWeight.Normal,
      [10, 10, 10],
      [18, 18, 18]
    )}
  `
);

export const LocationDivider = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.primary.main};
    width: 2px;

    ${fluidSizing([
      { prop: "height", values: [10, 10, 10] },
      { prop: "margin-left", values: [8, 10, 10] },
      { prop: "margin-right", values: [8, 10, 10] }
    ])}
  `
);

export const Title = styled.span<{ fadeAmount: number }>(
  ({ theme, fadeAmount }) => css`
    background-color: ${transparentize(
      fadeAmount,
      theme.colors.basic.silverChalicer
    )};
    display: block;
    height: 100%;
    pointer-events: none;
    text-transform: uppercase;
    width: 100%;

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Bold,
      [12, 12, 12],
      [14, 14, 14]
    )}

    ${fluidSizing([{ prop: "padding", values: [10, 10, 10] }])}
  `
);
