import { EApiEndpoint } from "src/config/enums";
import { apiPostCall } from "src/services/api/api.core";

export const contactCallBack = async (data: {
  honorific?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  clinicId?: number;
  phone?: string;
  query?: string;
  newsletter?: boolean;
  hp?: string;
  hpReq?: string;
}): Promise<any> => {
  return await apiPostCall(
    EApiEndpoint.FormsContactUs,
    JSON.stringify({
      prefix: data.honorific,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
      query: data.query,
      clinicId: data.clinicId,
      newsletter: data.newsletter,
    }),
    {
      alwaysRespond: true,
    }
  );
};
