import * as Styled from "./OxCallout.styled";

import React from "react";
import { OxLink } from "src/components/OxLink";
import { OxTextParagraph } from "src/components/OxTextParagraph";
import { OxTextTagline } from "src/components/OxTextTagline";
import { OxTextHeading } from "src/components/OxTextHeading";
import { OxButton } from "src/components/OxButton";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";

type TProps = {
  tag?: string;
  heading?: string;
  description?: string;
  buttons?: { cta: string; href: string }[];
  subsectionHeading?: string;
  subsectionItems?: {
    label: string;
    linkText: string;
    linkHref?: string;
  }[];
};

export const OxCallout = ({
  tag,
  heading,
  description,
  buttons = [],
  subsectionHeading,
  subsectionItems = []
}: TProps): JSX.Element => {
  return (
    <Styled.Container>
      {tag && <OxTextTagline>{tag}</OxTextTagline>}
      {heading && <OxTextHeading>{heading}</OxTextHeading>}
      {description && (
        <OxTextParagraph textAlign="center">{description}</OxTextParagraph>
      )}
      {buttons.length > 0 && (
        <Styled.Buttons>
          <OxThemedInput theme={EInputTheme.GoldTransparentAlternative4}>
            {buttons?.map((button, index) => (
              <Styled.Button key={index}>
                <OxButton asAnchor to={button.href}>
                  {button.cta}
                </OxButton>
              </Styled.Button>
            ))}
          </OxThemedInput>
        </Styled.Buttons>
      )}
      {(subsectionHeading || subsectionItems.length > 0) && (
        <Styled.Subsection>
          {subsectionHeading && (
            <Styled.SubsectionHeader>
              {subsectionHeading}
            </Styled.SubsectionHeader>
          )}
          {subsectionItems.length > 0 && (
            <Styled.SubsectionList>
              {subsectionItems.map((item, index) => (
                <React.Fragment key={index}>
                  <dt>{item.label}</dt>
                  <dd>
                    {item.linkHref ? (
                      <OxLink to={item.linkHref}>{item.linkText}</OxLink>
                    ) : (
                      item.linkText
                    )}
                  </dd>
                </React.Fragment>
              ))}
            </Styled.SubsectionList>
          )}
        </Styled.Subsection>
      )}
    </Styled.Container>
  );
};
