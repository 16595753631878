import styled, { css } from "styled-components";
import { OxIcon } from "src/components/OxIcon";
import { fluidSizing } from "src/utils";
import { OxImage } from "src/components/OxImage";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";

export const Carousel = styled.div`
  align-items: center;
  display: flex;

  ${fluidSizing([{ prop: "margin-top", values: [20, 40, 40] }])}
`;

export const Arrow = styled(OxIcon)<{ reversed?: boolean; disabled?: boolean }>(
  ({ theme, reversed, disabled }) => css`
    color: ${disabled
      ? theme.colors.secondary.main
      : theme.colors.primary.main};
    cursor: ${disabled ? "disabled" : "pointer"};
    transform: rotate(${reversed ? "-30deg" : "30deg"});

    ${fluidSizing([
      { prop: "margin-left", values: !reversed ? [] : [null, 20, 20] },
      { prop: "margin-right", values: reversed ? [] : [null, 20, 20] },
      { prop: "width", values: [20, 40, 30] }
    ])}
  `
);

export const List = styled.ul`
  display: flex;
  width: 100%;
`;

export const Item = styled.li<{ active?: boolean }>(
  ({ active }) => css`
    display: ${active ? "block" : "none"};
    flex: 1;

    ${fluidSizing([
      { prop: "margin-left", values: [null, 20, 20] },
      { prop: "margin-right", values: [null, 20, 20] }
    ])}
  `
);

export const Image = styled(OxImage)`
  ${fluidSizing([
    { prop: "height", values: [20, 20, 20] },
    { prop: "margin-bottom", values: [20, 20, 20] }
  ])}
`;

export const MobileNav = styled.div(
  ({ theme }) => css`
    align-items: center;
    color:  ${theme.colors.primary.main};
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;

    ${fluidSizing([{ prop: "margin-top", values: [20, 20, 20] }])}

    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Medium,
      [10, 10, 10],
      [18, 18, 18]
    )}

    ${theme.breakpoints.up("s")} {
      display: none;
    }
  `
);
