import styled, { ThemedStyledProps, css } from "styled-components";

import { TBasicTheme } from "src/types/theme.types";
import { TOxFlexProps } from "./OxFlex";

type TFlexboxWrapperProps = ThemedStyledProps<TOxFlexProps, TBasicTheme>;

export const FlexboxWrapper = styled.div((props: TFlexboxWrapperProps) => {
  const itemStyle = css`
    flex-grow: ${props.flexGrow || 0};
    flex-basis: ${props.flexBasis || "auto"};
    flex-shrink: ${props.flexShrink || 1};
    flex: ${props.flex || "0 1 auto"};
  `;
  const flexboxStyle = css`
    justify-content: ${props.justifyContentStart
      ? "flex-start"
      : props.justifyContentEnd
      ? "flex-end"
      : props.justifyContentCenter
      ? "center"
      : props.justifyContentBetween
      ? "space-between"
      : props.justifyContentAround
      ? "space-around"
      : "initial"};
    flex-direction: ${props.flexDirectionRow
      ? "row"
      : props.flexDirectionColumn
      ? "column"
      : "initial"};
    flex-wrap: ${props.wrap ? "wrap" : props.nowrap ? "nowrap" : "initial"};
    align-items: ${props.alignItemsStart
      ? "flex-start"
      : props.alignItemsEnd
      ? "flex-end"
      : props.alignItemsCenter
      ? "center"
      : props.alignItemsStretch
      ? "stretch"
      : props.alignItemsBaseline
      ? "baseline"
      : "initial"};
  `;
  return css`
    display: ${props.isItem ? "block" : "flex"};
    ${props.isItem ? itemStyle : flexboxStyle};
  `;
});
