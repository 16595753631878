import * as Styled from "./OxSuccess.styled";

import { DateTime } from "luxon";
import { OxOrderSummary } from "../OxOrderSummary";
import React, { useContext, useEffect, useRef } from "react";
import { OxLink } from "src/components/OxLink";
import { ERoutes } from "src/config/enums";
import { LocationsData } from "src/constants/locations";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";

type TProps = {
  show: boolean;
  type: "contactUs" | "callBack" | "feedback" | "booking";
  subtitle?: string;
  title?: string;
  summaryTitle?: string;
  bottomText?: string;
  hideSummary?: boolean;
  position?: string;
  noHeight?: boolean;
  formData: {
    name?: string;
    surname?: string;
    email?: string;
    query?: string;
    consultationDate?: DateTime;
    consultationTime?: DateTime | string;
    clinicId?: number;
    contactMethod?: string;
  };
};

type TSummaryData = {
  title: string;
  value: string;
  hide?: boolean;
}[];

export const OxSuccess = ({
  show,
  formData,
  type,
  title,
  subtitle,
  summaryTitle,
  bottomText,
  hideSummary,
  position,
  noHeight,
}: TProps): JSX.Element => {
  const { locations } = useContext(WebsiteDataContext);
  const containerRef = useRef(null);
  const formatDate = (date?: DateTime | string): string => {
    if (!date || typeof date === "string") return date || "";
    const newFormat = Object.assign(DateTime.DATE_FULL, {
      weekday: "long",
    });
    return date.setLocale("en-GB").toLocaleString(newFormat);
  };

  const formatTime = (time?: DateTime | string): string => {
    if (!time || typeof time === "string") return time || "";
    return time.setLocale("en").toFormat("t");
  };

  const getClinic = (clinicId?: number): string =>
    `Ouronyx ${locations.find((location) => location.id === clinicId)?.city}`;

  const dataCallBack: TSummaryData = [
    { title: "Date", value: formatDate(formData.consultationDate) },
    { title: "Time", value: formatTime(formData.consultationTime) },
  ];

  const dataContactUs: TSummaryData = [
    { title: "Forename", value: `${formData.name}` },
    { title: "Surname", value: `${formData.surname}` },
    { title: "Email", value: `${formData.email}` },
    { title: "Query", value: `${formData.query}` },
  ];

  const dataBooking: TSummaryData = [
    { title: "First Name", value: `${formData.name}` },
    { title: "Last Name", value: `${formData.surname}` },
    { title: "Date", value: formatDate(formData.consultationDate) },
    { title: "Timeslot", value: formatTime(formData.consultationTime) },
    { title: "Location", value: getClinic(formData.clinicId) },
    { title: "Contact via", value: `${formData.contactMethod}` },
  ];

  const titleText = (): string => {
    const defaultText = "";

    switch (true) {
      case !!title:
        return title ?? defaultText;
      case type === "callBack":
        return "We look forward to speaking with you!";
      case type === "booking":
        // return "Your request was successfully sent to our team who will contact you within the next working day to finalize your appointment.";
        // return "We look forward to seeing you!";
        return "";
      default:
        return defaultText;
    }
  };

  const subtitleText = (): string => {
    const defaultText =
      "An Ouronyx representative will contact you within 24 hours.";

    switch (true) {
      case !!subtitle:
        return subtitle ?? defaultText;
      case type === "callBack":
        return "We have sent you a confirmation email.";
      case type === "booking":
        return "Your request was successfully sent to our team who will contact you within the next working day to finalize your appointment.";
      default:
        return defaultText;
    }
  };

  const summaryTitleText = (): string => {
    const defaultText = "Your Request Summary";

    switch (true) {
      case !!summaryTitle:
        return summaryTitle ?? defaultText;
      case type === "callBack":
        return "Your Callback Summary";
      case type === "booking":
        return "Booking Request Summary";
      default:
        return defaultText;
    }
  };

  const bottomTextContent = (): string | JSX.Element | null => {
    const defaultText = "We look forward to speaking with you!";

    switch (true) {
      case !!bottomText:
        return bottomText ?? defaultText;
      case type === "callBack":
        return null;
      // return "If you want to amend or cancel this callback, please contact london@ouronyx.com";
      case type === "booking":
        return "We have sent this summary request to your email";
      default:
        return null;
      // return defaultText;
    }
  };

  const getSummaryData = (): TSummaryData => {
    switch (true) {
      case type === "callBack":
        return dataCallBack;
      case type === "booking":
        return dataBooking;
      default:
        return dataContactUs;
    }
  };

  useEffect(() => {
    window.scrollTo(
      0,
      (containerRef?.current as unknown as HTMLDivElement)?.offsetTop
    );
  }, [show]);

  return (
    <Styled.Container
      show={show}
      ref={containerRef}
      position={position}
      noHeight={noHeight}
    >
      <OxOrderSummary
        title={
          <>
            Thank you{formData.name ? `, ${formData.name}` : ""}.<br />{" "}
            {titleText()}
          </>
        }
        subtitle={subtitleText()}
        summaryTitle={summaryTitleText()}
        summaryData={getSummaryData()}
        hideSummary={hideSummary}
        bottomText={bottomTextContent()}
      />
    </Styled.Container>
  );
};
