import React from "react";
import { resolve } from "src/services/cms/componentResolver";
import { TLooseObject } from "src/services/api/api.types";
import { OnyxError } from "src/helpers/OnyxError";
import { EAlertVariant, TAlertData } from "src/context/AlertContext";

type TProps = {
  identifier: string;
  settings: TLooseObject;
  eagerLoad: boolean;
};

export const create = ({
  identifier,
  settings,
  eagerLoad,
}: TProps): React.ReactFragment => {
  if (!identifier)
    throw new OnyxError({
      type: EAlertVariant.Error,
      message: "Identifier must be set to create component",
    } as TAlertData);
  const Component = resolve(identifier);
  return <Component {...settings} convertBlocks eagerLoad={eagerLoad} />;
};
