import { OxBackToTop } from "src/components/OxBackToTop";
import { OxComponentContainer } from "src/components/OxComponentContainer";
import { OxPageHelmet } from "src/components/OxPageHelmet";
import React from "react";
import { create } from "src/services/cms/blockFactory";
import { Page } from "src/services/cms/cms.types";

type TProps = {
  pageContext: {
    page: Page;
  };
};

const Cms = ({ pageContext: { page } }: TProps): JSX.Element => {
  const { meta, blocks } = page;

  return (
    <>
      <OxPageHelmet {...meta} />
      {blocks?.map(({ identifier = "", settings = {} }, index) => (
        <OxComponentContainer key={index} marginBottom={[40, 40, 40]}>
          {create({ identifier, settings, eagerLoad: index === 0 })}
        </OxComponentContainer>
      ))}

      <OxBackToTop />
    </>
  );
};

export default Cms;
