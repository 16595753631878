// import * as Styled from "./OxServiceSet.styled";

import React, { useState } from "react";
import { EOxStoryVariant, OxStory } from "src/components/OxStory";
import { OxSanityBlockContent } from "src/components/OxSanityBlockContent";

export type TServiceSetItem = {
  tag?: string;
  headline?: string;
  description?: string;
  descriptionBlocks?: any;
  openCta?: string;
  ctaLink?: string;
  fluidImage?: any;
};

type TProps = {
  convertBlocks?: boolean;
  items: TServiceSetItem[];
};

export const OxServiceSet = ({
  convertBlocks,
  items = [],
}: TProps): JSX.Element => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggleOpen = (index: number) => {
    setOpenIndexes((prev) => {
      if (prev.includes(index)) {
        const key = openIndexes.findIndex((val) => val === index);
        delete prev[key];
      } else {
        prev.push(index);
      }

      return [...prev];
    });
  };

  return (
    <>
      {items.map((item, index, arr) => (
        <OxStory
          fullBleedContent
          key={index}
          fluidImagesArray={[item.fluidImage]}
          label={item.tag}
          title={item.headline}
          description={
            openIndexes.includes(index) || !!item.ctaLink ? (
              convertBlocks ? (
                <OxSanityBlockContent blocks={item.descriptionBlocks} />
              ) : (
                item.description
              )
            ) : (
              ""
            )
          }
          linkTitle={
            openIndexes.includes(index)
              ? "Close"
              : item.openCta ?? "Explore Treatment"
          }
          linkClick={() => {
            !item.ctaLink && toggleOpen(index);
          }}
          linkUrl={item.ctaLink}
          variant={
            index % 2 === 0
              ? EOxStoryVariant.ImageRight
              : EOxStoryVariant.ImageLeft
          }
          isLast={arr.length - 1 === index}
        />
      ))}
    </>
  );
};
